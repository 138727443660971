import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import GetStarted from '../../components/cta/GetStarted';
import NonStretchedImage from '../../components/layout/NonStretchedImage';

import { ViewportBreakpoints } from '../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';
import Dropdown from '@rotaready/frecl/build/Dropdown';

const TitleWrapper = styled.div`
  text-align: center;
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 80px 0;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 0 0 80px 0;
  }
`;

const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Tile = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 60px;
  opacity: ${props => props.fadeOut ? 0.3 : 1};
  filter: grayscale(${props => props.fadeOut ? 1 : 0});
  transition: all .15s ease-in-out;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: column;
    margin: 20px 0 100px 0;
    flex: 0 1 calc(33% - 20px);
  }
`;

const ImageContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grey10};
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  height: 240px;
  border-radius: 3px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const TilePaddedContainer = styled.div`
  margin-bottom: 20px;
`;

const TileIndustry = styled.div`
  > div {
    display: inline-block;
  }
`;

const LogoContainer = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  max-height: 40px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  margin: 20px;
  flex: 0 1 auto;
  padding: 10px;
`;

const LinkWithArrow2 = styled(LinkWithArrow)`
  span {
    flex: none;
  }
`;

const industries = [
  { value: 'all', label: 'All industries' },
  { value: 'hospitality', label: 'Hospitality' },
  { value: 'leisure', label: 'Leisure' },
  { value: 'retail', label: 'Retail' },
];

function findIndustryById(id) {
  return industries.find(i => i.value === id);
}

const tiles = [
  {
    text: 'Find out how implementing Rotaready has enabled Mollie\'s Motel to transform workforce management across the business',
    slug: 'hospitality-mollies-motel',
    industry: findIndustryById('hospitality'),
    imageKey: 'mollies',
  },
  {
    text: 'Learn how The Pizza Room has been able to better deploy over 20 hours each week since using Rotaready, and achieve an ROI of 572%',
    slug: 'hospitality-the-pizza-room',
    industry: findIndustryById('hospitality'),
    imageKey: 'pizzaRoom',
  },
  {
    text: 'Since implementing Rotaready, Riding House Cafes have been able to reduce labour costs by 2% and achieve an ROI of 698%',
    slug: 'hospitality-riding-house-cafes',
    industry: findIndustryById('hospitality'),
    imageKey: 'ridingHouseCafes',
  },
  {
    text: 'Learn how Rotaready helped this Glasgow based restaurant group better deploy 44 hours every week, and achieve an ROI of 524%',
    slug: 'hospitality-restaurant-group',
    industry: findIndustryById('hospitality'),
    imageKey: 'restaurantGroup',
    noLogo: true,
  },
  {
    text: 'Here\'s how Superbowl UK have been able to better deploy over 45 hours each week since using Rotaready, and achieve an ROI of 556%',
    slug: 'leisure-superbowl-uk',
    industry: findIndustryById('leisure'),
    imageKey: 'superbowl',
  },
  {
    text: 'Since implementing Rotaready across all seven of their venues, Itison has achieved an ROI of over 760%.',
    slug: 'hospitality-itison',
    industry: findIndustryById('hospitality'),
    imageKey: 'itison',
  },
  {
    text: 'Learn how Rotaready helped Brucan Pubs significantly reduce admin heavy tasks, improve operational efficiency and better deploy 21 hours every week as a result',
    slug: 'hospitality-brucan-pubs',
    industry: findIndustryById('hospitality'),
    imageKey: 'brucanPubs',
  },
  {
    text: 'Since getting started with Rotaready, Hickory\'s have been able to better deploy 146 hours every week and achieve an ROI of 652%. Find out how...',
    slug: 'hospitality-hickorys',
    industry: findIndustryById('hospitality'),
    imageKey: 'hickorys',
  },
  {
    text: 'Find out how the integration between Rotaready and PayCaptain helped Flourish improve payroll efficiency and ensure employees are always paid accurately and on time',
    slug: 'hospitality-flourish',
    industry: findIndustryById('hospitality'),
    imageKey: 'flourish',
  },
  {
    text: 'Here\'s how Fired Up Hospitality have been able to better deploy over 25 hours each week since using Rotaready, and achieve an ROI of 600%',
    slug: 'hospitality-fired-up',
    industry: findIndustryById('hospitality'),
    imageKey: 'firedUpHospitality',
  },
  {
    text: 'Since getting started with Rotaready, Pizza Pilgrims have been able to better deploy 54 hours every week and achieved an ROI of 485%. Here\'s how...',
    slug: 'hospitality-pizza-pilgrims',
    industry: findIndustryById('hospitality'),
    imageKey: 'pizzaPilgrims',
  },
  {
    text: 'Find out how the integration between Rotaready and Tevalis helped Maray improve data accuracy and gain greater visibility over costs.',
    slug: 'hospitality-maray',
    industry: findIndustryById('hospitality'),
    imageKey: 'maray',
  },
  {
    text: 'Find out how Rotaready not only helped Manorview build more intelligent staff rotas, but save a six-figure sum on wage costs.',
    slug: 'hospitality-manorview',
    industry: findIndustryById('hospitality'),
    imageKey: 'manorview',
  },
  {
    text: 'Since using Rotaready, MJMK has managed to reduce its wage percentage by over 5%. Here\'s how...',
    slug: 'hospitality-mjmk',
    industry: findIndustryById('hospitality'),
    imageKey: 'mjmk',
  },
  {
    text: 'Since getting started with Rotaready, Namco have been able to better deploy over 85 hours every week and achieved an ROI of 687%. Here\'s how...',
    slug: 'leisure-namco',
    industry: findIndustryById('leisure'),
    imageKey: 'namco',
  },
  {
    text: 'Here\'s how Roseacre Pub Company have been able to better deploy over 26 hours each week since using Rotaready, and achieve an ROI of 505%',
    slug: 'hospitality-roseacre',
    industry: findIndustryById('hospitality'),
    imageKey: 'roseacre',
  },
  {
    text: 'Learn how Rotaready has helped Mark Sargeant save huge amount of time on admin heavy tasks and make payroll more accurate than ever.',
    slug: 'hospitality-mark-sargeant',
    industry: findIndustryById('hospitality'),
    imageKey: 'marksargeant',
  },
  {
    text: 'This is how easyCoffee moved from pen and paper to real-time wage control across their estate in only a fortnight.',
    slug: 'hospitality-easycoffee',
    industry: findIndustryById('hospitality'),
    imageKey: 'easycoffee',
  },
  {
    text: 'Since implementing Rotaready, Signature Pub Group has been able to better deploy over 93 hours every week and achieved an ROI of over 600%.',
    slug: 'hospitality-signature-pub-group',
    industry: findIndustryById('hospitality'),
    imageKey: 'signaturepubs',
  },
  {
    text: 'Since getting started with Rotaready, Commture 365 have been able to better deploy over 28 hours every week and achieved an ROI of 321%. Here\'s how...',
    slug: 'retail-ee-commture365',
    industry: findIndustryById('retail'),
    imageKey: 'commture365',
  },
  {
    text: 'Here\'s how the world\'s leading photo capture company rolled out Rotaready across 12 countries in under six months, including a real-time HR integration.',
    slug: 'retail-picsolve',
    industry: findIndustryById('retail'),
    imageKey: 'picsolve',
  },
  {
    text: 'Since getting started with Rotaready, Golden Lion Group have been able to better deploy 16 hours per week. Find out how.',
    slug: 'hospitality-golden-lion-group',
    industry: findIndustryById('hospitality'),
    imageKey: 'glg',
  },
  {
    text: 'Here\'s how the team at Sankey\'s managed to save themselves almost an entire day every week by ditching their laborious approach to staff scheduling.',
    slug: 'hospitality-sankeys',
    industry: findIndustryById('hospitality'),
    imageKey: 'sankeys',
  },
  {
    text: 'Find out how Foodstory Cafe have reduced time spent on payroll and HR related tasks by 2 days every month.',
    slug: 'hospitality-foodstory-cafe',
    industry: findIndustryById('hospitality'),
    imageKey: 'foodstory',
  },
  {
    text: 'Find out how The Throckmorton managed to say goodbye to spreadsheets and eliminate mistakes in payroll, all whilst better deploying over three hours each week.',
    slug: 'hospitality-the-throckmorton',
    industry: findIndustryById('hospitality'),
    imageKey: 'throckmorton',
  },
  {
    text: 'Here\'s how one of O2\'s largest franchises, has taken its workforce management to the next level and made life easier for the entire business since working with Rotaready.',
    slug: 'retail-o2-bak',
    industry: findIndustryById('retail'),
    imageKey: 'bak',
  },
];

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

class ResourcesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndustry: industries[0],
    };
  }

  onIndustryChange = (selectedIndustry) => {
    this.setState(() => ({ selectedIndustry }));
  };

  render() {
    const {
      data,
    } = this.props;

    const {
      selectedIndustry,
    } = this.state;

    const {
      value: selectedIndustryValue,
    } = selectedIndustry;

    return (
      <Body header={header}>
        <SEO
          title="Customer stories - Rotaready"
          description="Meet our customers. From big retail businesses to small independent cafes and bars; here's what our customers have to say about us."
          url="customer-stories"
        />

        <ResponsiveContainer>
          <TitleWrapper>
            <H1 uistyle="brand160" text="Customer stories" />
          </TitleWrapper>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <FilterWrapper>
            <Dropdown
              options={industries}
              onChange={this.onIndustryChange}
              value={selectedIndustry}
              placeholder={{ initial: 'Highlight by industry' }}
              size="md"
            />
          </FilterWrapper>

          <TileContainer>
            {tiles.map((tile, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <Tile key={index} fadeOut={selectedIndustryValue !== 'all' && tile.industry.value !== selectedIndustryValue}>
                <TilePaddedContainer>
                  <TileIndustry>
                    <Pill size="sm" uistyle="primary" text={tile.industry.label} />
                  </TileIndustry>
                </TilePaddedContainer>

                <Link to={`/customer-stories/${tile.slug}`}>
                  <ImageContainer backgroundImage={data[`${tile.imageKey}Header`].childImageSharp.fluid.src}>
                    {!tile.noLogo && (
                      <LogoContainer>
                        <NonStretchedImage
                          fluid={data[`${tile.imageKey}Logo`].childImageSharp.fluid}
                          style={{ width: '300px', height: '40px' }}
                        />
                      </LogoContainer>
                    )}
                  </ImageContainer>
                </Link>

                <TilePaddedContainer>
                  <Text text={tile.text} size="lg" />
                </TilePaddedContainer>

                <LinkWithArrow2
                  direction="right"
                  to={`/customer-stories/${tile.slug}`}
                  text="Read their story"
                  render={props => <Link {...props} />}
                />
              </Tile>
            ))}
          </TileContainer>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default ResourcesPage;

export const query = graphql`
  query {
    foodstoryHeader: file(relativePath: { eq: "customer-stories/header-foodstory.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    foodstoryLogo: file(relativePath: { eq: "customer-stories/logo-foodstory.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    marksargeantHeader: file(relativePath: { eq: "customer-stories/header-marksargeant.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    marksargeantLogo: file(relativePath: { eq: "customer-stories/logo-marksargeant.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    bakHeader: file(relativePath: { eq: "customer-stories/header-bak.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    bakLogo: file(relativePath: { eq: "customer-stories/logo-bak.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    commture365Header: file(relativePath: { eq: "customer-stories/ee-commture365/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    commture365Logo: file(relativePath: { eq: "customer-stories/ee-commture365/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    glgHeader: file(relativePath: { eq: "customer-stories/header-glg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    glgLogo: file(relativePath: { eq: "customer-stories/logo-glg.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    picsolveHeader: file(relativePath: { eq: "customer-stories/header-picsolve.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    picsolveLogo: file(relativePath: { eq: "customer-stories/logo-picsolve.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    easycoffeeHeader: file(relativePath: { eq: "customer-stories/header-easycoffee.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    easycoffeeLogo: file(relativePath: { eq: "customer-stories/logo-easycoffee.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    throckmortonHeader: file(relativePath: { eq: "customer-stories/header-throckmorton.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    throckmortonLogo: file(relativePath: { eq: "customer-stories/logo-throckmorton.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    sankeysHeader: file(relativePath: { eq: "customer-stories/header-sankeys.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    sankeysLogo: file(relativePath: { eq: "customer-stories/logo-sankeys.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    signaturepubsHeader: file(relativePath: { eq: "customer-stories/header-signaturepubs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    signaturepubsLogo: file(relativePath: { eq: "customer-stories/logo-signaturepubs.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    manorviewHeader: file(relativePath: { eq: "customer-stories/header-manorview.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    manorviewLogo: file(relativePath: { eq: "customer-stories/logo-manorview.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    marayHeader: file(relativePath: { eq: "customer-stories/maray/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    marayLogo: file(relativePath: { eq: "customer-stories/maray/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    mjmkHeader: file(relativePath: { eq: "customer-stories/header-mjmk.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    mjmkLogo: file(relativePath: { eq: "customer-stories/logo-mjmk.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    namcoHeader: file(relativePath: { eq: "customer-stories/header-namco.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    namcoLogo: file(relativePath: { eq: "customer-stories/logo-namco.webp" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    roseacreHeader: file(relativePath: { eq: "customer-stories/header-roseacre.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    roseacreLogo: file(relativePath: { eq: "customer-stories/logo-roseacre.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    pizzaPilgrimsHeader: file(relativePath: { eq: "customer-stories/pizza-pilgrims/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    pizzaPilgrimsLogo: file(relativePath: { eq: "customer-stories/pizza-pilgrims/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    firedUpHospitalityHeader: file(relativePath: { eq: "customer-stories/fired-up-hospitality/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    firedUpHospitalityLogo: file(relativePath: { eq: "customer-stories/fired-up-hospitality/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    flourishHeader: file(relativePath: { eq: "customer-stories/flourish/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    flourishLogo: file(relativePath: { eq: "customer-stories/flourish/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    hickorysHeader: file(relativePath: { eq: "customer-stories/hickorys/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    hickorysLogo: file(relativePath: { eq: "customer-stories/hickorys/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    brucanPubsHeader: file(relativePath: { eq: "customer-stories/brucan-pubs/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    brucanPubsLogo: file(relativePath: { eq: "customer-stories/brucan-pubs/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    itisonHeader: file(relativePath: { eq: "customer-stories/itison/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    itisonLogo: file(relativePath: { eq: "customer-stories/itison/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    superbowlHeader: file(relativePath: { eq: "customer-stories/superbowl/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    superbowlLogo: file(relativePath: { eq: "customer-stories/superbowl/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    restaurantGroupHeader: file(relativePath: { eq: "customer-stories/restaurant-group/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    molliesHeader: file(relativePath: { eq: "customer-stories/mollies-motel/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    molliesLogo: file(relativePath: { eq: "customer-stories/mollies-motel/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    ridingHouseCafesHeader: file(relativePath: { eq: "customer-stories/riding-house-cafes/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    ridingHouseCafesLogo: file(relativePath: { eq: "customer-stories/riding-house-cafes/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    pizzaRoomHeader: file(relativePath: { eq: "customer-stories/the-pizza-room/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          src
        }
      }
    }

    pizzaRoomLogo: file(relativePath: { eq: "customer-stories/the-pizza-room/logo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 40, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
